import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';

import "./loader.scss";

export default class IVPLoader extends IVPLitElementBase {

	static get tag() { return 'ivp-loader' }

    static get properties() {
        return {

        };
    }

    constructor() {
        super();

        this.message = "Caricamento dati..."
    }

    render() {
        return html`
        <!--div class="text-center">
            <div class="spinner-border" role="status">
            <span class="sr-only">Caricamento dati...</span>
            </div>
        </div-->
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">${this.message}</span>
                </div>
                </div>
                <div class="row justify-content-center">
                <strong>${this.message}</strong>
            </div>
        </div>                
        `;
    }

    createRenderRoot() {
        return this;
    }

    static Show(elContainer,message) {
        if (elContainer == null)
			elContainer = document.body;

		let vels = document.querySelectorAll("ivp-loader")
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })
        let loader = document.createElement(this.tag);
        if (message != null)
            loader.message = message
        elContainer.prepend(loader);

        return loader;
    }

    static Remove() {
        let vels = document.querySelectorAll("ivp-loader")
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })
    }
}

IVPLoader.RegisterElement()