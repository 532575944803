import axios from 'axios'

const api_base_url = "https://e401-2a01-4f8-c012-302f-00-1.ngrok-free.app"

export default class Api {

    static async DoIO(func, method, params, is_json) {
        let data = params
        let headers = { "Authorization": `Bearer ${Api.auth_token}` }

        headers["Content-Type"] =  'application/json'
        data = JSON.stringify(params)

        let res = await axios({
            "method": method,
            "url": `${api_base_url}${func}`,
            "data": data,
            "headers": headers
        })

        return res.data
    }

    static async Init(auth_token) {
        Api.auth_token = auth_token
        return this.DoIO("/api/auth","get")
    }

    static async Login(login, psw) {        
        let auth_token = await this.DoIO("/api/auth","post",{login:login,secret:psw})

        Api.auth_token = auth_token

        return auth_token
    }

    static async GetYards() {        
        return this.DoIO("/api/yards","get")
    }

    static async GetYard(id) {        
        return this.DoIO("/api/yards/"+id,"get")
    }

    static async SetYardOperator(yard_id,operator_id) {        
        return this.DoIO(`/api/yards_operator/${yard_id}/${operator_id}`,"post")
    }

    static async SaveYardProperties(yard_id,section,properties) {        
        return this.DoIO(`/api/yards_properties/${yard_id}/${section}`,"post",{properties: properties})
    }

    static async GetContractors() {        
        return this.DoIO("/api/contractors","get")
    }

    static async GetContractor(id) {        
        return this.DoIO("/api/contractors/"+id,"get")
    }

    static async GetContractorPrivacy() {        
        return this.DoIO("/api/contractors_privacy","get")
    }    

    static async SetContractorPrivacy() {        
        return this.DoIO("/api/contractors_privacy","put")
    }       

    static async GetOperators() {        
        return this.DoIO("/api/operators","get")
    }   

    static async GetOperator(operator_id) {        
        return this.DoIO("/api/operators/"+operator_id,"get")
    }       

    static async SaveOperator(data) {     
        if (parseInt(data.id)>0)   
            return this.DoIO("/api/operators/"+data.id,"post",data)
        else
            return this.DoIO("/api/operators","put",data)
    }       
    
    static async UploadInit(file_name) {
        return this.DoIO("/api/auth/upload/"+file_name,"get")
    }

}