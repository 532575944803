import Api from "./lib/api.js"


export default class Auth {

    static async Init() {

        try {
            let auth_token = localStorage.getItem("auth_token")
            let ret = await Api.Init(auth_token)
            if (ret.profile == null)
                throw "login"

            Auth.user = ret

            document.querySelector("body").classList.add(Auth.user.profile)
            document.querySelector(".USER").innerHTML = Auth.user.login
            document.querySelector(".LOGOUT").addEventListener("click",async ()=>{
                localStorage.setItem("auth_token","")
                document.location.href = "./auth-login.html"    
            })
        }
        catch(Ex) {
            console.log(Ex)
            document.location.href = "./auth-login.html"
        }

    }

    static async Login(login, psw) {
        try {
            let auth = await Api.Login(login,psw)
            localStorage.setItem("auth_token",auth.token)

            return true
        }
        catch (Ex) {
            return false
        }
    }

}

window.Auth = Auth
