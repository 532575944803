import Auth from './auth.js'
import Api from './lib/api.js';
import DlgPrivacy from './lib/privacy.js'
import IVPLoader from './lib/loader.js'
import DlgSelectOperator from './lib/select-operator-dialog.js';
import DlgPopup from './lib/popup-dialog.js';


document.addEventListener("DOMContentLoaded",async()=>{

    document.querySelector("#sidebar-menu .yards").classList.add("active")

    document.querySelector(".navbar-header .breadcrumb").append($(`<li class="breadcrumb-item active">Pratiche</li>`)[0])
    

    IVPLoader.Show()

    await Auth.Init()

    if (Auth.user.privacy_accepted == false)
        await DlgPrivacy.EnsureAccepted()

    document.querySelector("#layout-wrapper").classList.remove("hidden")
        
    // Default Datatable
    let resp = await Api.GetYards()

    let columns = [                        
        { title: 'Nome', data: 'name' },
        { title: 'Indirizzo', data: 'address' },
        { title: 'Città', data: 'city_province' },
        //{ title: 'Cap', data: 'zip' },
        //{ title: 'Importo', data: 'amount' },          
        { title: 'Contractor', data: 'contractor_name' }, 
        { title: 'Operatore', data: 'operator_name' },
        { title: 'Stato', data: 'status' },
        { title: ' ', data: '' },
    ]

    if (Auth.user.profile == "CUSTOMER")
        columns.splice(4,1)

    let btb = $('#basic-datatable').DataTable({
        fnInitComplete: function(){
            $('div.toolbar').html('Custom tool bar!');
        },        
        "pageLength": 50,
        "language": {
            "paginate": {
                "previous": "<i class='mdi mdi-chevron-left'>",
                "next": "<i class='mdi mdi-chevron-right'>"
            },
            processing:     "Elaborazione dati...",
            search:         "Ricerca&nbsp;:",
            lengthMenu:    "Visualizza _MENU_ record",
            info:           "Visualizzati da _START_ a _END_ di _TOTAL_ record",
            infoEmpty:      "Nessun record in archivio",
            infoFiltered:   "(trovati su _MAX_ in totale)",
            infoPostFix:    "",
            loadingRecords: "Caricamento dati...",
            zeroRecords:    "Nessun record",
            emptyTable:     "Archivio vuoto",
            sortAscending:  ": activer pour trier la colonne par ordre croissant",
            sortDescending: ": activer pour trier la colonne par ordre décroissant"
        },

        columns: columns,
        columnDefs: [
            {   
                targets: [-1],
                render: function (data, type, row) {
                    return `
                        <div class="d-flex flex-row">
                        <button type='button' class='ADMIN-ONLY btnOperator btn btn-outline-secondary btn-sm mr-1'>Operatore</button>
                        <button type='button' class='btnEdit btn btn-outline-secondary btn-sm '>Modifica</button>
                        </div>
                    `
                },                
                
            },
            {
                "targets": [-2],
                "render": function ( data, type, row, meta ) {
                    if (data == 'INPUT_DATA')
                        return "Caricamento dati"

                    return ""                    
                }
              }
        ],
        data: resp
    });

    let fnEdit = ()=> {
        $("#basic-datatable .btnEdit").off('click')
        $("#basic-datatable .btnEdit").click((e)=>{
            let tr = btb.row(e.target.closest('tr'))
            document.location.href = "yard.html?id="+tr.data().id
            console.log(tr.data())
        })
    }

    fnEdit()

    let fnOperator = ()=> {
        $("#basic-datatable .btnOperator").off('click')
        $("#basic-datatable .btnOperator").click(async (e)=>{
            try {
                let tr = btb.row(e.target.closest('tr'))
                IVPLoader.Show()
                let oprs = await Api.GetOperators()
                IVPLoader.Remove()
                let chosen_operator_id = await DlgSelectOperator.Show(tr.data().name, tr.data().operator_id, oprs)
                if (chosen_operator_id != null) {
                    IVPLoader.Show()
                    await Api.SetYardOperator(tr.data().id,chosen_operator_id)
                    let resp = await Api.GetYards()
                    btb.clear();
                    btb.rows.add(resp);                    
                    btb.draw();
                    IVPLoader.Remove()                    
                }
            }
            catch (Ex) {
                IVPLoader.Remove()                    
                console.error(Ex)
                DlgPopup.Show("Errore","Ooops! Qualcosa è andato storto. Riprovare.")
            }
        })
    }    
    fnOperator()
    
    btb.on( 'draw init.dt', fnEdit)
    btb.on( 'draw init.dt', fnOperator)

    IVPLoader.Remove()
})